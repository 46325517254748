<template>
  <div class="what-did-wrong-wrapper" :class="{ 'training-in-progress': trainingInProgress == true }">
    <!-- Welcome Screen - Training Not In Progress -->
    <div class="welcome-screen" v-if="!trainingInProgress && !trainingFinished">
      <div class="title">
        <img class="whitelabel-logo" v-if="landingPage.whitelabelConfiguration" :src="landingPage.whitelabelConfiguration.logo_dark_url" alt="">
        <h1>You've Been Phished!</h1>
        <p>Don't worry, this is just an internal cybersecurity test. Don't tell anyone! Learn how we phished you with our interactive training below.</p>

        <a v-if="!trainingFinished" :class="{ 'disabled': trainingInProgress }" class="button"
          @click.prevent="startTraining">
          {{ trainingInProgress ? 'In Progress...' : startTrainingMessage }}
        </a>
        <p style="font-weight: 500;" v-else>Training complete! 😃</p>
      </div>
    </div>
    <!-- / Welcome Screen - Training Not In Progress -->

    <!--  Training Screen -->
    <div class="training-screen" v-if="trainingInProgress && !trainingFinished">
      <div class="title">
        <h1>Learn what you missed, so you can spot real phishing emails in the future!</h1>
      </div>
      <VOnboardingWrapper ref="wrapper" :steps="steps" @finish="handleTrainingFinished" />
      <!-- Browser -->
      <div class="browser">
        <div class="browser-navigation-bar">
          <i></i><i></i><i></i>
          <!-- Place your URL into <input> below -->
          <input :value="`From: ${landingPage.email.sendingProfileFromAddress}`" disabled />
        </div>

        <div class="browser-container">
          <div v-html="emailHtml"></div>
          <!-- Place your content of any type here -->
          <!-- <h3>Supports content of any kind</h3>
        <p>Add <strong>width</strong> or <strong>height</strong> to <u>.browser</u> class to change size of browser
          window.</p>
        <p>Add (or change) <strong>font-size</strong> to scale browser elements.</p>
        <img src="http://placehold.it/720x405" alt="Browser content"> -->
        </div>
      </div>
      <!-- / Browser -->
    </div>
    <!-- / Training Screen -->

    <!-- Training Finished -->
    <div class="welcome-screen" v-if="trainingFinished">
      <div class="title">
        <h1>Training Complete!</h1>
        <p>Great job! You've completed the training. You can close this window now.</p>
      </div>
    </div>
    <!-- / Training Finished -->
  </div>
</template>

<script>
import { ref } from 'vue'
import { VOnboardingWrapper, useVOnboarding } from 'v-onboarding'
import 'v-onboarding/dist/style.css'
import axios from 'axios'

export default {
  props: ['landingPage', 'apiBase', 'emailGuid'],

  data() {
    return {
      trainingFinished: false,
      trainingInProgress: false,
      emailHtml: null,

      countdown: null,
      countdownTimer: null
    }
  },

  created() {
    this.determineEmailHtml();

    if (!this.countdown && !this.trainingInProgress) {
      // Start coutndown timer
      this.countdown = 20;
      this.countdownTimer = setInterval(() => {
        this.countdown--;
        if (this.countdown <= 0) {
          clearInterval(this.countdownTimer);
          this.startTraining();
        }
      }, 1000);
    }
  },

  components: {
    VOnboardingWrapper
  },

  computed: {
    startTrainingMessage() {
      let message = 'See What You Missed';

      if (this.countdown) {
        message = message + ' - Starting In ' + this.countdown + '...';
      }

      return message;
    },

    whatDidWrongSpans() {
      // Get css selector and content of all spans in the email with class 'what-did-wrong' 
      let matches = this.emailHtml.match(/<span class="what-did-wrong" data-index="(.*?)" data-title="(.*?)" data-content="(.*?)">.*?<\/span>/g)
      if (matches) {
        return matches.map((match, index) => {
          let content = match.match(/data-content="(.*?)"/)[1]
          let dataIndex = match.match(/data-index="(.*?)"/)[1]
          let title = match.match(/data-title="(.*?)"/)[1]
          let selector = `span.what-did-wrong[data-index="${dataIndex}"]`

          return { selector, title, content }
        })
      }
      return []
    },

    steps() {
      let steps = this.whatDidWrongSpans.map(({ selector, title, content }) => {
        return {
          attachTo: { element: selector },
          content: { title: (title ? title : 'Notice...'), description: content }
        }
      })
      // Prepend a browser-navigation-bar step
      steps.unshift({
        attachTo: { element: '.browser-navigation-bar' },
        content: { title: 'Always Check The Sender Address', description: 'Always check the sender email matches the official company domain. It doesn\'t here!' }
      })
      // Prepend a welcome step
      // steps.unshift({
      //   attachTo: { element: '.title h1' },
      //   content: {
      //     title: 'Welcome to the training!',
      //     description: 'This training will help you understand what you did wrong and how to avoid it in the future.'
      //   }
      // })
      return steps
    }
  },

  setup() {
    const wrapper = ref(null)

    const { start, goToStep, finish } = useVOnboarding(wrapper)

    return {
      wrapper,
      start,
      finish,
      goToStep
    }
  },

  methods: {
    determineEmailHtml() {
      let html = this.landingPage.email.html

      // For all spans with class 'what-did-wrong', move content to a data attribute
      // html = html.replace(/<span class="what-did-wrong">(.*?)<\/span>/g, (match, content) => {
      //  return `<span class="what-did-wrong-visible" data-content="${content}"></span>`
      // })

      // Add numeric index to each span with class 'what-did-wrong-visible'
      html = html.replace(/<span class="what-did-wrong"/g, (match, index) => {
        return `<span class="what-did-wrong" data-index="${index}"`
      })

      this.emailHtml = html;
    },

    startTraining() {
      if (this.trainingInProgress) {
        return false;
      }

      this.trainingInProgress = true

      this.$nextTick(() => {
        // wait half a second
        setTimeout(() => {
          this.start()
        }, 500)
      })
    },

    handleTrainingFinished() {
      this.trainingInProgress = false
      this.trainingFinished = true;
      axios.post(this.apiBase + '/email-landing-page/' + this.emailGuid + '/training-complete')
        .then(() => {
          console.log('Training complete');
        })
        .catch(error => {
          console.error(error);
        });
    }
  }
}
</script>

<style lang="scss">
.what-did-wrong-wrapper {
  // background: #76bed0;
  min-height: 100vh;
  background: #000;
  text-align: center;
  // padding: 2em;
  // padding-top: 1em;
  height: 100%;
  width: 100%;

  .whitelabel-logo {
    max-width: 200px;
    margin-bottom: 1.5em;
    max-height: 120px;
    width: auto;
    height: auto;
  }

  .v-onboarding-item  {
    font-family: IBM Plex Sans Hebrew, sans-serif !important;
  }

  .v-onboarding-item .v-onboarding-item__description {
    font-weight: 300;
  }

  .v-onboarding-btn-primary {
    font-weight: 400;
  }

  .v-onboarding-item__header-close {
    display: none !important;
  }

  .title {
    text-align: center;
    // padding: 2em;
    color: #fff;
    // margin-bottom: 25px;
    // margin-bottom: 2em;
    font-family: IBM Plex Sans Hebrew, sans-serif;
  }

  .title h1 {
    font-weight: 400;
  }

  .title p {
    font-weight: 300;
  }

  .title .button {
    display: inline-block;
    padding: 0.8em 1.5em;
    // margin-top: 0.5em;
    border-radius: 0.25em;
    background: #fff;
    color: #000;
    // border: 1px solid #fff;
    border: none;
    cursor: pointer;
    transition: all 0.3s ease;

    &:hover,
    &.disabled {
      background: rgba(255, 255, 255, 0.75);
    }

    &.disabled {
      cursor: not-allowed;
    }
  }

  .welcome-screen {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    padding: 2em;
    width: 100%;

    h1 {
      font-size: 2.2em;
      margin-bottom: 1em;
    }

    p {
      margin-bottom: 2.5em;
    }
  }

  .training-screen {
    padding: 2em;
    padding-top: 0.5em;

    .title {
      margin-bottom: 2em;
    }
  }

  img {
    width: 100%;
  }

  // Browser
  .browser {
    font-size: 18px;
    padding: 2.1em 0 0 0;
    border-radius: 0.25em;
    background: #ddd;
    display: inline-block;
    position: relative;
    overflow: hidden;
    box-shadow: 0 0.25em 0.9em -0.1em rgba(0, 0, 0, .3);
  }

  .browser .browser-navigation-bar {
    display: block;
    box-sizing: border-box;
    height: 2.1em;
    position: absolute;
    top: 0;
    padding: 0.3em;
    width: 100%;
    background: linear-gradient(to bottom, #edeaed 0%, #dddfdd 100%);
    border-bottom: 2px solid #cbcbcb;
  }

  .browser i {
    display: inline-block;
    height: 0.7em;
    width: 0.7em;
    border-radius: 0.45em;
    background-color: #eee;
    margin: 0.4em 0.15em;
  }

  .browser i:nth-child(1) {
    background-color: rgb(255, 86, 79)
  }

  .browser i:nth-child(1):hover {
    background-color: rgb(255, 20, 25)
  }

  .browser i:nth-child(2) {
    background-color: rgb(255, 183, 42)
  }

  .browser i:nth-child(2):hover {
    background-color: rgb(230, 175, 42)
  }

  .browser i:nth-child(3) {
    background-color: rgb(37, 198, 58)
  }

  .browser i:nth-child(3):hover {
    background-color: rgb(10, 225, 10)
  }

  .browser input {
    font-size: 0.75em;
    vertical-align: top;
    display: inline-block;
    height: 1.6em;
    color: #aaa;
    width: calc(100% - 6em);
    border: 0.1em solid #E1E1E1;
    border-radius: 0.25em;
    background-color: #eee;
    margin: 0.1em;
    padding: 0 0.4em;
  }

  .browser-container {
    height: 100%;
    width: 100%;
    overflow-x: hidden;
    overflow-y: auto;

    text-align: center;

    max-width: 1000px;
  }

  &.training-in-progress {
    .what-did-wrong-inline {
      // background-color: #ff0000;
      color: #fff;
      padding: 0.5em;
      border-radius: 100%;
      margin: 0.5em;
      display: block;
      width: 10px;
      height: 10px;
      margin: 0 auto;
      text-align: center;
    }
  }
}
</style>